<template>
  <div class="employees">
    <el-row class="employee-title" :gutter="4">
      <el-col :span="18">
        <span class="h3">成员列表</span>
        <el-button size="mini" type="primary" @click="addEmployeeVisible=true" plain>新增成员</el-button>
      </el-col>
      <el-col :span="6" class="employee-list-filter">
        <el-form label-width="80px">
          <el-form-item label="条件搜索:">
            <el-input placeholder="请输入员工手机号" class="employee-input-filter"
                      prefix-icon="el-icon-search" v-model="pagination.phone"
                      size="mini"
                      @change="loadEmployeeSearch"
                      clearable></el-input>
          </el-form-item>
        </el-form>
      </el-col>
    </el-row>
    <div class="employee-data-list">
      <el-table :data="employees" align="center">
        <empty slot="empty"></empty>
        <el-table-column label="成员信息" align="center">
          <template slot-scope="scope">
            <div class="employee-info">
              <div class="employee-info-img" v-if="scope.row.avatar"><img :src="scope.row.avatar"/></div>
              <div class="employee-info-img" v-if="!scope.row.avatar"><img :src="src"/></div>
              <div class="employee-info-detail">
                <p>{{scope.row.name}}</p>
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="手机" align="center" prop="phone"></el-table-column>
        <el-table-column label="部门" align="center" prop="departmentName"></el-table-column>
        <el-table-column label="绑定时间" align="center">
          <template slot-scope="scope">
            <span>{{new Date(scope.row.createdAt * 1000).Format('yyyy-MM-dd hh:mm:ss')}}</span>
          </template>
        </el-table-column>
        <el-table-column label="操作" align="center" prop="name">
          <template slot-scope="scope">
            <p v-show="scope.row.uuid != employee.enterpriseUuid">
              <span @click="handleEdit(scope.row)" class="employee-edit">编辑</span>
              <span @click="handleResetPassword(scope.row)" class="employee-edit">重置密码</span>
              <span @click="handleDelete(scope.row, scope.$index)" class="employee-delete">删除</span>
            </p>
            <p v-show="scope.row.uuid == employee.enterpriseUuid" disable>
              不可操作
            </p>

          </template>
        </el-table-column>
      </el-table>
    </div>
    <el-pagination
      @current-change="handleCurrentChange"
      class="pagination"
      :current-page="pagination.curPage"
      :page-size="pagination.limit"
      layout="total, prev, pager, next, jumper"
      :total="pagination.total">
    </el-pagination>

    <c-dialog :visible.sync="addEmployeeVisible" :title="'新增成员'" :width="'30rem'" :height="'28rem'">
      <el-form label-width="80px" class="add-employee-form" size="small">
        <el-form-item label="成员名称">
          <el-input placeholder="请输入成员名称" v-model="createEmployee.name"/>
        </el-form-item>
        <el-form-item label="头像">
          <img class="department-employee-img" :src="createEmployee.avatar" alt="">
        </el-form-item>
        <el-form-item label="手机">
          <el-input placeholder="请输入成员手机" v-model="createEmployee.phone"/>
        </el-form-item>
        <el-form-item label="验证码">
          <el-input placeholder="请输入验证码" v-model="createEmployee.code">
            <template slot="append">
              <div @click="sendCreateEmployeeSms">{{info}}</div>
            </template>
          </el-input>
        </el-form-item>
        <el-form-item label="登录密码">
          <el-input placeholder="请输入成员登录密码" v-model="createEmployee.password"/>
        </el-form-item>
        <el-form-item label="归属部门">
          <el-select v-model="createEmployee.departmentUuid" placeholder="请选择">
            <el-option
              v-for="item in canAllocateGroups"
              :key="item.uuid"
              :label="item.name"
              :value="item.uuid">
            </el-option>
          </el-select>
        </el-form-item>

<!--        <el-form-item label="授权角色">-->
<!--          <el-select v-model="createEmployee.roleUuid" placeholder="请选择">-->
<!--            <el-option-->
<!--              v-for="item in roles"-->
<!--              :key="item.uuid"-->
<!--              :label="item.name"-->
<!--              :value="item.uuid">-->
<!--            </el-option>-->
<!--          </el-select>-->
<!--        </el-form-item>-->

        <el-form-item>
          <el-button type="primary" @click="handleEmployeeCreate">确定</el-button>
        </el-form-item>
      </el-form>
    </c-dialog>

    <c-dialog :visible.sync="editEmployeeVisible" :title="'编辑成员'" :width="'30rem'" :height="'18rem'">
      <el-form label-width="80px" class="add-employee-form" size="small">
        <el-form-item label="成员名称">
          <el-input placeholder="请输入成员名称" v-model="editEmployee.name"/>
        </el-form-item>
        <el-form-item label="头像">
          <img class="department-employee-img" :src="editEmployee.avatar" alt="">
        </el-form-item>
        <el-form-item label="归属部门">
          <el-select v-model="editEmployee.departmentUuid" placeholder="请选择">
            <el-option
              v-for="item in canAllocateGroups"
              :key="item.uuid"
              :label="item.name"
              :value="item.uuid">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="handleEmployeeEdit">确定</el-button>
        </el-form-item>
      </el-form>
    </c-dialog>

    <c-dialog :visible.sync="updateEmployeeVisible" :title="'重置密码'" :width="'30rem'" :height="'12rem'">
      <el-form label-width="80px" class="add-employee-form" size="small">
        <el-form-item label="登录密码">
          <el-input placeholder="请输入登录密码" v-model="updateEmployee.password"/>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="handleEnterpriseResetEmployeePassword">确定</el-button>
        </el-form-item>
      </el-form>
    </c-dialog>


  </div>

</template>

<script>
  import {
    employeeUnion,
    employeeList,
    employeeDelete,
    employeeUpdate,
    employeeCreateCode,
    employeeCreate,
    enterpriseResetEmployeePassword
  } from '@/api/account/employee'
  import {departmentAll} from '@/api/account/department'
  // import {roleList} from '@/api/account/role'
  import CDialog from '@/components/Dialog'
  import {mapGetters} from 'vuex'
  import {checkPhone, getCodeTime} from "@/util/checkLogin";

  export default {
    name: '',
    components: {
      CDialog
    },
    mounted: function () {
      this.initEmployee()
    },

    activated: function () {
      departmentAll().then(res => {
        this.canAllocateGroups = res.data || []
      })
      //
      // roleList().then(res => {
      //   this.roles = res.data || []
      // })
    },

    computed: {
      ...mapGetters(['employee'])
    },
    data: function () {
      return {
        employees: [],
        roles: [],
        info: '获取验证码',
        editEmployeeVisible: false,
        updateEmployeeVisible: false,
        addEmployeeVisible: false,
        updateEmployee: {
          phone: "",
          password: "",
        },
        src: require('@/assets/img/avatar.jpg'),
        createEmployee: {
          name: "",
          avatar: '',
          departmentUuid: "",
          roleUuid: "",
          code: "",
          password: ""
        },

        editEmployee: {
          uuid: "",
          name: "",
          avatar: '',
          departmentUuid: "",
        },
        employeeGroup: {
          employeeUuid: '',
          groupUuid: []
        },
        canAllocateGroups: [],
        sendOk: true,
        pagination: {
          phone: '',
          start: 0,
          curPage: 1,
          limit: 10,
          total: 0,
        },
      }
    },
    methods: {
      initEmployee() {
        this.pagination.start = 0
        this.pagination.phone = ''
        employeeUnion(this.pagination).then(res => {
          this.employees = res.data.list || []
          this.pagination.total = parseInt(res.data.total)
        })
      },

      checkObj(obj, checkItem) {
        for (let i = 0; i < (checkItem || []).length; i++) {
          let item = checkItem[i]
          let v = obj[item.value]
          if (!v || v.length === 0) {
            return {
              status: false,
              message: item.message,
            }
          }
        }
        return {status: true}
      },
      handleEmployeeCreate() {
        let check = this.checkObj(this.createEmployee, [
          {value: "name", message: "请填写员工名称"},
          {value: "phone", message: "请填写员工手机"},
          {value: "code", message: "请填写验证码"},
          {value: "password", message: "请填写登录密码"},
          {value: "departmentUuid", message: "请选取员工所在部门"},
          // {value: "roleUuid", message: "请授权员工角色"},
        ])
        if (!check.status) {
          this.$message.warning(check.message)
          return
        }

        employeeCreate(this.createEmployee).then(res => {
          this.addEmployeeVisible = false
          this.$message.success('创建成功')
          this.createEmployee = {
            name: "",
            avatar: '',
            departmentUuid: "",
            roleUuid: "",
            code: "",
            password: ""
          }
          this.initEmployee()
        })


      },

      handleCurrentChange(value) {
        this.pagination.curPage = value
        this.pagination.start = (value - 1) * this.pagination.limit
        employeeList(this.pagination).then(res => {
          this.employees = res.data
        })
      },

      loadEmployeeSearch() {
        let vue = this
        this.pagination.curPage = 1
        this.pagination.start = 0
        employeeUnion(this.pagination).then(res => {
          vue.employees = res.data.list || []
          vue.pagination.total = parseInt(res.data.total)
        })
      },

      handleEdit(row) {
        this.editEmployee = {
          uuid: row.uuid,
          name: row.name,
          avatar: row.avatar,
          // departmentUuid: row.departmentUuid,
        }
        this.editEmployeeVisible = true
      },

      handleEmployeeEdit() {

        let check = this.checkObj(this.editEmployee, [
          {value: "name", message: "请填写员工名称"},
          {value: "departmentUuid", message: "请选取员工所在部门"},
          {value: "uuid", message: "请选取员工"},
        ])

        if (!check.status) {
          this.$message.warning(check.message)
          return
        }
        employeeUpdate(this.editEmployee).then(res => {
          this.$message.success('更新成功')
          this.handleCurrentChange(this.pagination.curPage)
          this.editEmployeeVisible = false
        })
      },

      handleResetPassword(row) {
        this.updateEmployee = {
          phone: row.phone,
          password: ''
        }
        this.updateEmployeeVisible = true

      },

      handleEnterpriseResetEmployeePassword() {

        if (!this.updateEmployee.password) {
          this.$message.warning('请输入重置密码')
          return
        }

        enterpriseResetEmployeePassword(this.updateEmployee).then(res => {
          this.updateEmployeeVisible = false
          this.$message.success('重置成功')
        })

      },

      sendCreateEmployeeSms() {
        let _this = this
        if (this.createEmployee.phone) {
          if (_this.info.indexOf('获取验证码') === -1) {
            _this.$message.error('请耐心等待短信发送')
            return
          }
          let checkPhoneObj = checkPhone(this.createEmployee.phone);
          if (!checkPhoneObj.status) {
            this.$message.error(checkPhoneObj.msg);
            return;
          }
          employeeCreateCode({phone: this.createEmployee.phone}).then(res => {
            if (res.code === 0) {
              _this.$message.success('短信已发送，请注意查收')
              getCodeTime(function (value) {
                _this.info = value;
              }, () => {
                _this.info = '获取验证码'
                _this.sendOk = true
              });
            }
          })
        } else {
          _this.$message.error('请输入员工手机号码')
        }
      },
      handleDelete(row) {
        this.$confirm('此操作将移除成员, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          employeeDelete({uuid: row.uuid}).then(() => {
            this.$message.success('删除成功')
            this.initEmployee()
          })
        }).catch(() => {

        })
      },
    }
  }
</script>

<style lang="scss">
  .employees {
    padding: $padding 0;
    display: flex;
    flex-direction: column;

    .employee-title {
      margin-bottom: $margin;

      .h3 {
        margin-right: $margin;
      }

      .el-form {
        .el-form-item {
          margin-bottom: 0;
        }
      }
    }

    .employee-data-list {
      flex: 1;

      .employee-info {
        display: flex;
        align-items: center;
        text-align: center;

        .employee-info-img {
          img {
            max-width: 2rem;
          }
        }

        .employee-info-detail {
          margin-left: $padding;

          p:first-child {
            font-size: .8rem;
          }
        }
      }



      .el-table--group::after, .el-table--border::after, .el-table::before {
        background-color: transparent;
      }

      .el-table__row:hover {
        // background-color: $bg_sec_color;
      }

      .employee-edit, .employee-delete {
        cursor: pointer;
        display: inline-block;
        margin-right: $padding;
      }

      .employee-edit {
        color: $btn_color;
      }

      .employee-delete {
        color: $btn_sec_color;
      }

    }

    .pagination {
      margin: $padding;
      text-align: right;
    }

    .add-employee-form {
      padding: $padding;

      .el-input-group__append {
        border: none;
        border-left: 1px solid;
        cursor: pointer;
        color: $_theme_color;
        @include set_border_color($--border-color, $--border-color-dark);
        @include set_bg_color(#f3f4f7, $background-color-dark)
      }

    }

  }

</style>
